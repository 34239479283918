<!-- New Component Name: Editorial Carousel, EditorialCarousel -->

<template>
  <div class="highlight-ecom">
    <div class="module__header text-center">
      <VaimoHeading
        v-if="heading"
        :heading="heading"
        :heading-link="sectionData.headingLink"
        class="module__heading mb-base"
      />
      <VaimoTruncatedText
        v-if="description"
        :text="description"
        class="module_descr"
        truncate="by lines"
        :lines="truncatedLines"
        animated-text
      />
    </div>

    <VaimoProducts
      v-if="productsSKU.length"
      :unique-key="uid"
      :sku-list="productsSKU"
      :sku-list-type="skuListType"
      :nosto-id="nostoSlot"
      :alt-visuals="altVisuals"
      :wrapper-props="sliderProps"
      class="mt-md"
    />
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  onBeforeMount,
  ref
} from '@nuxtjs/composition-api';

import { useProductGroup } from '~/diptyqueTheme/composable/useProductGroup';
import { useScreenSize } from '~/diptyqueTheme/composable/useScreenSize';

export default defineComponent({
  name: 'HighlightEcom',
  components: {
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoTruncatedText: () => import('atoms/VaimoTruncatedText.vue'),
    VaimoProducts: () => import('organisms/VaimoProducts.vue')
  },
  props: {
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const { isDesktop } = useScreenSize();
    const { getTransformedData } = useProductGroup({});
    const productGroup = ref([]);
    const heading = computed(() => props.sectionData?.heading);
    const description = computed(() => props.sectionData?.descr);
    const productsSKU = computed(() => productGroup.value?.[0]?.sku || []);
    const skuListType = computed(
      () => productGroup.value?.[0]?.type || 'listOfSku'
    );
    const nostoSlot = computed(() => productGroup.value?.[0]?.nostoSlot || '');
    // eslint-disable-next-line no-magic-numbers
    const truncatedLines = computed(() => (isDesktop.value ? 3 : 2));
    const uid = computed(() => 'he_' + (props.sectionData.sys?.id || ''));
    const sliderProps = { slider: { mobileShift: 25 } };
    const altVisuals = computed(
      () => props.sectionData?.productGroup?.altvisCollection?.items || []
    );

    const updateProductGroup = async () => {
      productGroup.value = await getTransformedData([
        props.sectionData?.productGroup
      ]);
    };

    onBeforeMount(async () => {
      await updateProductGroup();
    });

    return {
      isDesktop,
      heading,
      description,
      truncatedLines,
      productsSKU,
      productGroup,
      sliderProps,
      altVisuals,
      uid,
      nostoSlot,
      skuListType
    };
  }
});
</script>

<style lang="scss" scoped>
.module__header {
  margin: 0 var(--spacer-md);
  @include for-screen-m {
    max-width: 610px;
    padding: 0 var(--spacer-md);
    margin: 0 auto;
  }
  @include for-screen-l {
    padding-left: 375px;
    padding-right: 375px;
  }
}
::v-deep .vaimo-truncated-text {
  font-weight: 300;
}
</style>
